import React from "react";
import { Link } from "react-router-dom";

const LocationCard = ({ location }) => {
  return (
    <div className="location-card">
      <h3>{location.name}</h3>
      <p>{location.address}</p>
      <Link to={`/feedback/${location.id}`}>Generate Feedback Link</Link>
    </div>
  );
};

export default LocationCard;
