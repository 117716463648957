import React, { useEffect, useState } from "react";
import axios from "axios";
import LocationCard from "../components/LocationCard";
import Header from "../components/Header";

const DashboardPage = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/locations`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((response) => setLocations(response.data))
        .catch((error) => console.error("Error fetching locations:", error));
    }
  }, []);

  return (
    <div>
      <Header />
      <h2>Your Business Locations</h2>
      {locations.length === 0 ? (
        <p>No locations found.</p>
      ) : (
        <div>
          {locations.map((location) => (
            <LocationCard key={location.id} location={location} />
          ))}
        </div>
      )}
    </div>
  );
};

export default DashboardPage;
