import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear user data from local storage and redirect to login
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <header className="header">
      <div className="header-container">
        <h1>GMB Reputation Management</h1>
        <nav>
          <Link to="/dashboard">Dashboard</Link>
          <button onClick={handleLogout}>Logout</button>
        </nav>
      </div>
    </header>
  );
};

export default Header;
