import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const FeedbackPage = () => {
  const { locationId } = useParams();
  const [feedbackURL, setFeedbackURL] = useState("");

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/locations/${locationId}/feedback-url`
      )
      .then((response) => setFeedbackURL(response.data.feedbackURL))
      .catch((error) => console.error("Error fetching feedback URL:", error));
  }, [locationId]);

  const handleThumbsUp = () => {
    window.location.href = feedbackURL;
  };

  const handleThumbsDown = () => {
    alert("Please provide your feedback.");
  };

  return (
    <div>
      <h2>Leave Feedback</h2>
      <button onClick={handleThumbsUp}>👍 Thumbs Up</button>
      <button onClick={handleThumbsDown}>👎 Thumbs Down</button>
    </div>
  );
};

export default FeedbackPage;
